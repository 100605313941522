/* --- Global --- */
import {Helmet} from 'react-helmet';

/* --- Local --- */
import wave from '@assets/images/bump.png';
import {ShowcaseHome} from '@views';

import {QuestCard} from '@components';
import questListConsenQuest from '@data/questListConsenQuest';
import questListMillionDevs from '@data/questListMillionDevs';

/* --- Component --- */
const PageHome = props => {
  return (
    <>
      <SearchEngineOptimization />
    </>
  );
};

const SearchEngineOptimization = props => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>EthQuest - Adventure of A Lifetime</title>
      <meta property="og:url" content="http://ethquest.io" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="EthQuest - Adventure of A Lifetime" />
      <meta
        property="og:description"
        content="Experience the Ethereum blockchain with quests and adventures."
      />
    </Helmet>
  );
};

export default PageHome;
