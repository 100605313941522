import {FormStartProject} from '@forms';

import wave from '@assets/images/wave.png';
import OMD from '@assets/images/omd-icon.jpg';

const ShowcaseHome = props => {
  return (
    <Atom.Flex
      center
      column
      sx={{
        color: 'white',
        minHeight: [0, 0, '80vh'],
        // py: 5,
        // pb: 7,
        zIndex: 1000,
      }}>
      <Atom.BackgroundGradient gradient="bluePurple" direction={0} />
      <Atom.Absolute sx={{bottom: 0, left: 0, right: 0}}>
        <Atom.Image src={wave} sx={{height: 130, width: '100%'}} />
      </Atom.Absolute>
      <Atom.Container>
        <Atom.Flex
          center
          co
          sx={{
            flexDirection: ['column', 'column', 'column'],
          }}>
          {/* Left : Region */}
          <Atom.Box sx={{flex: 2, textAlign: 'center'}}>
            <Atom.Heading
              giga
              sx={{
                fontSize: [5, 5, 5, 6],
              }}>
              <Atom.Span sx={{fontWeight: 300}}>Ethereum Quests</Atom.Span>
              <br />
              <Atom.Span
                sx={{
                  fontSize: [5, 5, 5],
                }}>
                Pick a Journey. Get Rewarded.
              </Atom.Span>
            </Atom.Heading>
            <Atom.Flex alignCenter sx={{mt: 4}}>
              {/* ConsenQuest */}
              <Molecule.Link to="/adventure/consenquest" sx={{mr: 3}}>
                <Atom.Button tag sx={{p: 4}}>
                  <Atom.Flex center column>
                    <Atom.Flex alignCenter>
                      <Atom.Image
                        sx={{
                          borderRadius: 9999,
                          width: 52,
                        }}
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSymm752bT_zWHbWNoZZsHOgrfQslCfjLp7HuCco7dNezKp9K_2NQ&s"
                      />
                      <Atom.Span xl sx={{mx: 2}}>
                        ConsenQuest
                      </Atom.Span>
                    </Atom.Flex>
                    <Atom.Span sm sx={{mt: 2}}>
                      0.1 ETH Reward to experience Developer Products
                    </Atom.Span>
                  </Atom.Flex>
                </Atom.Button>
              </Molecule.Link>
              {/* OMD Quest */}
              <Molecule.Link to="/adventure/devquest" sx={{ml: 3}}>
                <Atom.Button tag sx={{p: 4, textAlign: 'center'}}>
                  <Atom.Flex center column>
                    <Atom.Flex alignCenter>
                      <Atom.Image
                        sx={{
                          borderRadius: 9999,
                          width: 52,
                        }}
                        src={OMD}
                      />
                      <Atom.Span xl sx={{mx: 2}}>
                        DevQuest
                      </Atom.Span>
                    </Atom.Flex>
                    <Atom.Span sm sx={{mt: 2}}>
                      Limited Edition onemilliondevs.eth sub-domain
                    </Atom.Span>
                  </Atom.Flex>
                </Atom.Button>
              </Molecule.Link>
            </Atom.Flex>
          </Atom.Box>
          {/* Right : Region */}
          <Atom.Box
            sx={{
              flex: 1,
              mt: 4,
            }}>
            {/* <FormStartProject /> */}
          </Atom.Box>
        </Atom.Flex>
      </Atom.Container>
    </Atom.Flex>
  );
};

export default ShowcaseHome;
